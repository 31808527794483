/* © 2023 Organic Media Development. All Rights reserved. For more information: contact@organicmediadevelopment.com */

.logo-holder {
    background: none;
    width: 160px;
    height: 200px;
    position: relative;
    margin: 0 auto;
    padding-right: 50px;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-transform: translateZ(0);
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}

.bar {
    position: relative;
    height: 8px;
    background: #6a6a6a;
    width: 0%;
    top: 0px;
    left: 18px;
    margin-top: 8px;
    box-shadow: 0 0 3px rgba(192, 192, 192, 0.9);
    animation: fill 45s infinite alternate, colors 45s infinite alternate;
}

.bar.fill1 {
    animation: fill-1 25s infinite alternate, colors 25s infinite alternate;
}

.bar.fill2 {
    animation: fill-2 15s infinite alternate, colors 15s infinite alternate;
}

.bar.fill3 {
    animation: fill-3 35s infinite alternate, colors 35s infinite alternate;
}

.bar.fill4 {
    animation: fill-4 15s infinite alternate, colors 15s infinite alternate;
}

.bar.fill5 {
    animation: fill-5 45s infinite alternate, colors 45s infinite alternate;
}

.bar.fill6 {
    animation: fill-6 25s infinite alternate, colors 25s infinite alternate;
}

@keyframes fill {
    0% {
        width: 0;
    }
    33% {
        width: 30px;
    }
    66% {
        width: 10px;
    }
    100% {
        width: 105px;
    }
}

@keyframes fill-1 {
    0% {
        width: 0;
    }
    33% {
        width: 50px;
    }
    66% {
        width: 20px;
    }
    100% {
        width: 130px;
    }
}

@keyframes fill-2 {
    0% {
        width: 0;
    }
    33% {
        width: 90px;
    }
    66% {
        width: 70px;
    }
    100% {
        width: 136px;
    }
}

@keyframes fill-3 {
    0% {
        width: 0;
    }
    33% {
        width: 50px;
    }
    66% {
        width: 24px;
    }
    100% {
        width: 109px;
    }
}

@keyframes fill-4 {
    0% {
        width: 0;
    }
    33% {
        width: 98px;
    }
    66% {
        width: 34px;
    }
    100% {
        width: 99px;
    }
}

@keyframes fill-5 {
    0% {
        width: 0;
    }
    33% {
        width: 30px;
    }
    66% {
        width: 10px;
    }
    100% {
        width: 148px;
    }
}

@keyframes fill-6 {
    0% {
        width: 0;
    }
    33% {
        width: 48px;
    }
    66% {
        width: 22px;
    }
    100% {
        width: 140px;
    }
}

@keyframes colors {
    0% {
        background-color: #c9c9c9;
    }
    50% {
        background-color: yellowgreen;
    }
    100% {
        background-color: darkolivegreen;
    }
}
