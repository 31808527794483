/* © 2023 Organic Media Development. All Rights reserved. For more information: contact@organicmediadevelopment.com */

/* based on http://www.kylejlarson.com/blog/2011/how-to-create-pie-charts-with-css3/ */

.pie-wrap {
    height: 100px;
    position: relative;
    margin-top: 20%;
    margin-left: 30%;
}

.pie-wrap:before {
    content: "";
    display: block;
    background-color: #efefef;
    position: absolute;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    top: 0;
    left: 0;
}

.slice-wrap:before {
    content: " ";
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    clip: rect(0px, 50px, 100px, 0px);
    background-color: orange;
    opacity: 0.4;
    transform: rotate(10deg);
}

.slice-wrap {
    position: absolute;
    width: 100px;
    height: 100px;
    clip: rect(0px, 100px, 100px, 50px);
}

.slice2.slice-wrap {
    transform: rotate(180deg);
    animation: spin3 1s linear forwards 3s;
}

.slice1:before {
    animation: 8s spin linear forwards infinite;
}

.slice2:before {
    /* older webkit seems buggy with zero so use 0.00001 if you notice something strange*/
    transform: rotate(0deg);
    animation: 2s spin2 linear 2s forwards 3s;
}

.pie-wrap:after {
    /* remove this if you want pie shape to show*/
    content: " ";
    width: 80px;
    height: 80px;
    background: grey;
    position: absolute;
    top: 10px;
    left: 10px;
    border-radius: 50%;
    z-index: 999;
}

.pie-wrap2:after {
    display: none;
}

@keyframes spin {
    from {
        transform: rotate(10deg);
    }
    to {
        transform: rotate(180deg);
    }
}

@keyframes spin2 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(144deg);
    }
}
